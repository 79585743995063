import React, { useContext, useEffect } from 'react';
import Loader from '../../components/Loader/Loader';
import { UserContext } from '../../providers/user.provider';
import { Navigate } from 'react-router-dom';

const HomeV2 = () => {
  const { ownerId, urjanetIntegrationId, authToken, urjanet } = useContext(UserContext);
  //   console.log('ownerId:', ownerId);
  //   console.log('urjanetIntegrationId:', urjanetIntegrationId);
  //   console.log('authToken:', authToken);

  //   console.log('urjanet :', urjanet);
  useEffect(() => {
    console.log('authToken :', authToken);
  }, [urjanet]);

  return ownerId && urjanetIntegrationId && authToken ? (
    // <Navigate replace to={entryUrl || '/connect-utility'} />
    <Navigate replace to={'/utility/connect'} />
  ) : (
    <Loader showLoaderOnly={true} />
  );
};

export default HomeV2;
