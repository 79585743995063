import React, { useContext } from 'react';
import './Popover.scss';
import { PopoverContext } from '../../providers/popover.provider';

function Popover() {
  const { isPopoverOpen, closePopover, popoverParams, isCloseButton } = useContext(PopoverContext);

  return (
    <div className={`popover ${isPopoverOpen ? 'block' : '!hidden'}`}>
      <div className="popover-content">
        {(popoverParams.title || popoverParams.icon) && (
          <div className="border-b-2 border-solid border-gray-200 mb-[18px] text-primary text-left flex justify-between pb-2">
            {popoverParams.title}
            {popoverParams.icon}
          </div>
        )}
        <div className="text-primary py-[18px]">{popoverParams.content}</div>
        {popoverParams.buttons && (
          <div className="flex justify-center mt-4 space-x-2">
            {popoverParams.buttons.map((button, index) => (
              <button
                key={index}
                className="bg-[#F1D972] font-aeonik-bold py-2 px-4 text-primary rounded-md lg:text-lg text-sm hover:opacity-80"
                onClick={() => {
                  button.action && button.action();
                  closePopover();
                }}>
                {button.text}
              </button>
            ))}
          </div>
        )}
        {isCloseButton && (
          <button
            className="bg-[#F1D972] mt-[20px] font-aeonik-bold py-3 px-14 text-primary rounded-md lg:text-lg text-sm border-[#F1D972] cursor-pointer disabled:opacity-75  disabled:cursor-not-allowed disabled:bg-neutral disabled:text-gray-500 hover:opacity-80"
            onClick={closePopover}>
            {popoverParams.closeButtonText}
          </button>
        )}
      </div>
    </div>
  );
}

export default Popover;
