import React, { useContext } from 'react';
import { Navigate, Outlet, Route } from 'react-router-dom';
import { UserContext } from './providers/user.provider';
import Header from './components/Header/Header';
import AtomicShapesAnimation from './components/Background-animation/AtomicShapesAnimation';

const PrivateRoutes = ({ handleLogout }) => {
  const { currentUser, ownerId, urjanetIntegrationId } = useContext(UserContext);

  if (!(ownerId && urjanetIntegrationId)) {
    localStorage.setItem('entryUrl', window.location.pathname + window.location.search);
  }

  // console.log('currentUser :', currentUser);

  return ownerId && urjanetIntegrationId ? (
    <>
      {/* <Header handleLogout={handleLogout} /> */}

      <section className="relative overflow-hidden xl:pb-0 pb-8">
        <AtomicShapesAnimation>
          <div className="w-full mt-16 min-h-[calc(100vh-166px)]">
            <Outlet />
          </div>
        </AtomicShapesAnimation>
      </section>
    </>
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoutes;
