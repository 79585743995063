import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../providers/user.provider';
import { UtilityStatusType } from '../../../Types';

const UtilityStatus = () => {
  const { urjanet } = useContext(UserContext);
  const [urjanetData, setUrjanetData] = useState({ status: '' });

  useEffect(() => {
    if (urjanet && urjanet.status) {
      console.log('urjanet :', urjanet);
      // setUrjanetData(urjanet);
      // TODO: Remove Urjanet that's why we are returning force success
      setUrjanetData({ ...urjanet, status: UtilityStatusType.Success });
    }
  }, [urjanet]);

  return (
    <>
      <div className={`xl:mx-[211px] mx-[10px] mb-6 lg:pt-[150px] pt-0`}>
        <div className="text-center">
          <p className="text-primary text-[18px] md:text-[24px] lg:text-[32px] leading-1 lg:leading-[40px] py-2 mb-[14px]">
            {urjanetData.status === UtilityStatusType.Pending && (
              <span>
                Utilities are still stuck in the 20th century, so this may take a while &#128078;{' '}
                <br />
                We&apos;ll contact you if there are any issues. You can close this page.
              </span>
            )}
            {urjanetData.status === UtilityStatusType.Success && 'You are successfully connected.'}
            {urjanetData.status === UtilityStatusType.Failed && (
              <span>Your Credentials are incorrect please update.</span>
            )}
          </p>
        </div>
      </div>
    </>
  );
};

export default UtilityStatus;
