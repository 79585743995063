import { gql } from '@apollo/client';

export const LOAD_USERS = gql`
  query {
    getAllUsers {
      id
      firstName
      email
      password
    }
  }
`;

// export const URJANET_UTILITY_LIST = gql`
//   query {
//     getAllUsers {
//       id
//       firstName
//       email
//       password
//     }
//   }
// `;

export const URJANET_UTILITY_LIST = gql`
  query UrjanetListProvider(
    $providerName: String
    $providerNameContains: String
    $offset: Int!
    $first: Int!
  ) {
    urjanetUtilityProvider(
      providerName: $providerName
      providerNameContains: $providerNameContains
      offset: $offset
      first: $first
    ) {
      edges {
        node {
          id
          providerName
          providerId
        }
      }
    }
  }
`;

export const ME = gql`
  query {
    me {
      id
      accounts {
        accountType
        credential {
          id
          status
        }
      }
    }
  }
`;

export const INTEGRATIONS = gql`
  query Integrations {
    integrations {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const SOLAR_CONFIGURATION = gql`
  query SolarConfiguration {
    solarConfiguration {
      batteryCostPerBattery
      batteryItcRate
      batteryPaymentPerBattery
      daylightCashbackPpw
      daylightCashbackRate
      federalItcRate
      financeRate
      inflationRate
      solarDegradationFactor
      systemPriceRate
      termInYears
    }
  }
`;

export const UTILITY = gql`
  query Utility($state: String!, $zipcode: String) {
    utility(where: { state: $state, zipCode: $zipcode }) {
      zipCode
      name
      state
      utilityType
      avgResidentialRate
    }
  }
`;

export const SOLAR_INQUIRY_QUERY = gql`
  query SolarInquiryQuery {
    solarInquiry {
      id
      address
      batteryQuantity
      city
      earnCashbackUpTo
      electricityCost
      email
      energyBillSaving
      estSolarSystemSizeKw
      federalItcAmount
      monthlyEnergyCost
      netSolarSystemPrice
      phoneNumber
      progress
      savingOver25Years
      sizeSmoothing
      state
      systemPrice
      treesPlanted
      wattCost
      zipCode
    }
  }
`;

export const SOLAR_PROPOSAL_QUERY = gql`
  query SolarProposalQuery {
    solarProposal {
      customerAddress {
        address
        avgMonthlyElectricityCost
        city
        electricityCost
        email
        id
        latitude
        longitude
        phoneNumber
        progress
        state
        zipCode
      }
      batteryQuantity
      earnCashbackUpTo
      energyBillingSaving
      estSolarSystemSizeKw
      federalItc
      monthlyEnergyCost
      netSystemCost
      savingOver25Years
      sizeSmoothing
      systemCost
      systemSize
      treesPlanted
      wattCost
      id
    }
  }
`;

export const DECODE_NOUNCE_QUERY = gql`
  query DecodeNounce($nonce: UUID!) {
    decodeNounce(nonce: $nonce) {
      nounce
      restToken
      token
    }
  }
`;
