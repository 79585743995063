import { createContext, useEffect, useState } from 'react';
import { auth } from '../firebase.config';
import { useLazyQuery, useMutation } from '@apollo/client';
import { REQUEST_TOKEN } from '../GraphQL/Mutations';
import { DECODE_NOUNCE_QUERY, INTEGRATIONS, ME } from '../GraphQL/Queries';
import { UtilityStatusType } from '../Types';
import { useLocation, useNavigate } from 'react-router-dom';
import { redirectToExternalUrl } from '../utils/utils';
import Cookies from 'js-cookie';
import { cookieAttributes } from '../Constants';

export const UserContext = createContext();

const authUrl = process.env.REACT_APP_AUTH_URL;

export const UserProvider = ({ children }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  // const authTokenFromUrl = params.get('authToken');
  const authCode = params.get('authToken');
  const accessToken = Cookies.get('daylight_access_token');
  const urlFrom = window.location.protocol + '//' + window.location.host + '/';

  const navigate = useNavigate();
  const authTokenFromLocalStorage = localStorage.getItem('authToken');
  const [authToken, setAuthToken] = useState(accessToken);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [userProfile, setUserProfile] = useState(undefined);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [requestTokenResponse, setRequestTokenResponse] = useState('');
  const [ownerId, setOwnerId] = useState('');
  const [urjanetIntegrationId, setUrjanetIntegrationId] = useState('');
  const [isManualSignUp, setIsManualSignUp] = useState(false);
  const [isFirebasePasswordLessSignInLinkClicked, setIsFirebasePasswordLessSignInLinkClicked] =
    useState(false);
  const [urjanet, setUrjanet] = useState(null);

  //    mutations
  const [requestAuthToken] = useMutation(REQUEST_TOKEN);

  //   queries
  const [fetchMeData, { loading: meLoading, data: meData }] = useLazyQuery(ME);
  const [fetchIntegrationData, { loading: integrationLoading, data: integrationData }] =
    useLazyQuery(INTEGRATIONS);

  const [decodeNounceQuery, { loading: decodeNounceQueryLoading }] =
    useLazyQuery(DECODE_NOUNCE_QUERY);

  const fetchOwnerId = async (authToken) => {
    try {
      const meRes = await fetchMeData({
        context: {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      });

      if (meRes) {
        console.log('me data :', meRes);
        setOwnerId(meRes.data.me.id);

        if (meRes.data.me.accounts.length) {
          const credentialStatus = meRes.data.me.accounts[0].credential.status;
          //   const credentialStatus = 'Failed';
          //   setUrjanet({
          //     __typename: 'IntegrationAccountCredentialNode',
          //     id: 'SW50ZWdyYXRpb25BY2NvdW50Q3JlZGVudGlhbE5vZGU6NDIz',
          //     status: 'Failed'
          //   });
          setUrjanet(meRes.data.me.accounts[0].credential);

          if (credentialStatus === UtilityStatusType.Pending) {
            navigate('/utility/status', { replace: true });
          } else if (credentialStatus === UtilityStatusType.Failed) {
            navigate('/utility/update', { replace: true });
          } else if (credentialStatus === UtilityStatusType.Success) {
            navigate('/utility/status', { replace: true });
          } else {
            navigate('/utility/connect', { replace: true });
          }
        } else {
          navigate('/utility/connect', { replace: true });
        }
      }
    } catch (error) {
      console.log(error);
      redirectToExternalUrl(authUrl, urlFrom);
    }
  };

  const fetchIntegrationIds = async (authToken) => {
    try {
      const integrationIdsRes = await fetchIntegrationData({
        context: {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      });
      if (integrationIdsRes) {
        const urjanetIntegrationId =
          integrationIdsRes.data &&
          integrationIdsRes.data.integrations &&
          integrationIdsRes.data.integrations.edges.filter((e) => e.node.name === 'Urjanet');

        if (urjanetIntegrationId) {
          setUrjanetIntegrationId(urjanetIntegrationId[0].node.id);
        }
      }
    } catch (error) {
      console.log(error);
      redirectToExternalUrl(authUrl, urlFrom);
    }
  };

  // const firebaseLogout = async () => {
  //   await auth.signOut();
  //   setCurrentUser(undefined);
  //   setUserProfile(undefined);
  //   setIsLoggingOut(true);
  //   setIsFirebasePasswordLessSignInLinkClicked(false);
  //   setIsManualSignUp(false);
  // };

  // useEffect(() => {
  //   setIsLoading(true);
  //   auth.onAuthStateChanged(async (user) => {
  //     setIsLoading(false);
  //     if (user && (user.emailVerified || !user.emailVerified || user.phoneNumber)) {
  //       setCurrentUser(user);
  //       setIsLoggingOut(false);
  //     } else {
  //       setIsLoggingOut(true);
  //       setCurrentUser(null);
  //       // setIsManualSignUp(false);
  //       // setIsFirebasePasswordLessSignInLinkClicked(false);
  //       console.log('logout');
  //       // setUserProfile(undefined);
  //     }
  //   });
  //   // console.log('sss', currentUser);
  //   // console.log('ddd', userProfile);
  // }, []);

  const fetchUtilityData = async (authToken) => {
    try {
      if (authToken) {
        // console.log('authToken :', authToken);
        // setRequestTokenResponse(authToken);

        const ownerIdPromise = fetchOwnerId(authToken);
        const integrationIdsPromise = fetchIntegrationIds(authToken);

        // Wait for both promises to resolve
        await Promise.all([ownerIdPromise, integrationIdsPromise]);
        setIsLoading(false);
      }
    } catch (error) {
      // Handle any errors
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  // useEffect(() => {
  //   // console.log('dsddsdsdsd :', authToken);
  //   if (!authToken) {
  //     if (!authTokenFromUrl) {
  //       redirectToExternalUrl(authUrl, urlFrom);
  //     } else {
  //       localStorage.setItem('authToken', authTokenFromUrl);
  //       setAuthToken(authTokenFromUrl);
  //     }
  //   } else {
  //     authTokenFromUrl && localStorage.setItem('authToken', authTokenFromUrl);
  //     setRequestTokenResponse(authToken);

  //     fetchUtilityData(authToken);
  //     // fetchSolarConfigData();
  //     // checkProposalIsExist(email);
  //     // fetchOpenSolarProject();
  //   }
  // }, []);

  const fetchAccessToken = async (nonce) => {
    try {
      const decodeNounceRes = await decodeNounceQuery({
        variables: {
          nonce
        }
      });

      // console.log('decodeNounceRes :', decodeNounceRes);
      if (decodeNounceRes) {
        setAuthToken(decodeNounceRes.data.decodeNounce.token);
      }

      Cookies.set(
        'daylight_access_token',
        decodeNounceRes.data.decodeNounce.token,
        cookieAttributes
      );

      const currentUrl = window.location.href;
      console.log(currentUrl);
      const url = new URL(currentUrl);
      console.log(url);
      const path = url.pathname;

      navigate(path);
      return decodeNounceRes.data.decodeNounce;
    } catch (error) {
      // console.log('Dddd :', error);
      redirectToExternalUrl(authUrl, urlFrom);
    }
  };

  useEffect(() => {
    if (accessToken) {
      setAuthToken(accessToken);
      fetchUtilityData(accessToken);
    }
  }, []);

  useEffect(() => {
    if (!accessToken) {
      if (!authCode) {
        redirectToExternalUrl(authUrl, urlFrom);
      } else {
        fetchAccessToken(authCode);
      }
    }
  }, [authCode]);

  useEffect(() => {
    if (authToken) {
      setRequestTokenResponse(authToken);
      fetchUtilityData(authToken);
    }
  }, [authToken]);

  // useEffect(() => {
  //   // console.log('sss :', currentUser);
  //   setIsLoading(true);
  //   const loadRequestToken = async () => {
  //     if (
  //       currentUser &&
  //       (currentUser.email || currentUser.phoneNumber || currentUser.accessToken)
  //     ) {
  //       try {
  //         const authTokenResponse = await requestAuthToken({
  //           variables: {
  //             input: {
  //               firebase: {
  //                 idToken: currentUser.accessToken
  //               }
  //             }
  //           }
  //         });

  //         if (authTokenResponse) {
  //           setRequestTokenResponse(authTokenResponse.data.requestToken);

  //           const ownerIdPromise = fetchOwnerId(authTokenResponse.data.requestToken);
  //           const integrationIdsPromise = fetchIntegrationIds(authTokenResponse.data.requestToken);

  //           // Wait for both promises to resolve
  //           await Promise.all([ownerIdPromise, integrationIdsPromise]);
  //           setIsLoading(false);
  //         }
  //       } catch (error) {
  //         console.log(error);
  //         setIsLoading(false);
  //         // firebaseLogout();
  //       }
  //     } else {
  //       // console.log('dddooo');
  //       // setUserProfile(undefined);
  //       setIsLoading(false);
  //     }
  //   };
  //   loadRequestToken();
  // }, [currentUser]);

  const updateProfile = (profile) => {
    setUserProfile(profile);
  };

  return (
    <UserContext.Provider
      value={{
        currentUser,
        userProfile,
        authToken,
        setUserProfile,
        updateProfile,
        isLoggingOut,
        setIsLoggingOut,
        isLoading,
        setIsLoading,
        requestTokenResponse,
        setRequestTokenResponse,
        ownerId,
        setOwnerId,
        urjanetIntegrationId,
        setUrjanetIntegrationId,
        isManualSignUp,
        setIsManualSignUp,
        setIsFirebasePasswordLessSignInLinkClicked,
        isFirebasePasswordLessSignInLinkClicked,
        // firebaseLogout,
        setUrjanet,
        urjanet,
        fetchOwnerId,
        fetchIntegrationIds
      }}>
      {children}
    </UserContext.Provider>
  );
};
