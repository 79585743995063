import React, { useRef } from 'react';
import Avatar from '../Avatar/Avatar';

function transformimoji(x, y, memoji) {
  const box = memoji.getBoundingClientRect();
  const xPosMemoji = -(y - box.y - box.height) / 100;
  const yPosMemoji = (x - box.x - box.width) / 50;
  return (
    'perspective(100px) ' +
    '   rotateX(' +
    xPosMemoji +
    'deg) ' +
    '   rotateY(' +
    yPosMemoji +
    'deg) '
  );
}

const MemojiAnimation = ({ avatar, children }) => {
  const memojiRef = useRef(null);
  const memojiImgRef = useRef(null);

  function transformElement(el, xyEl) {
    if (el) {
      el.style.transform = transformimoji.apply(null, xyEl);
    }
  }

  function handleMouseMove(e) {
    const xy = [e.screenX, e.screenY];
    const position = xy.concat([memojiImgRef.current]);

    window.requestAnimationFrame(function () {
      transformElement(memojiImgRef.current, position);
    });
  }

  const handleChildRef = (ref) => {
    memojiImgRef.current = ref;
  };

  return (
    <div
      id="memoji"
      ref={memojiRef}
      onMouseMove={handleMouseMove}
      // className="min-h-[calc(100vh-290px)]"
    >
      <Avatar avatar={avatar} childRef={handleChildRef} />
      {children}
    </div>
  );
};

export default MemojiAnimation;
