import './App.css';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PageNotFound from './pages/PageNotFound';
import Login from './pages/Login/Login';
import Header from './components/Header/Header';
import { auth } from './firebase.config';
import PrivateRoutes from './PrivateRoutes';
import Loader from './components/Loader/Loader';
import { UserContext } from './providers/user.provider';
import { useContext, useEffect } from 'react';
import Popover from './components/Popup/Popover';
import UtilityConnect from './pages/Utility/UtilityConnect/UtilityConnect';
import UtilityStatus from './pages/Utility/UtilityStatus/UtilityStatus';
import Home from './pages/Home/Home';
import HomeV2 from './pages/Home/HomeV2';

function App() {
  const {
    isLoading,
    setUserProfile,
    setIsManualSignUp,
    setIsFirebasePasswordLessSignInLinkClicked
  } = useContext(UserContext);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const stateData = JSON.parse(urlParams.get('state'));
    // console.log('stateData :', stateData);
    if (stateData && stateData.userData) {
      // console.log('sss');
      // console.log('Ddd');
      setUserProfile(stateData.userData);
      setIsManualSignUp(true);
      setIsFirebasePasswordLessSignInLinkClicked(true);
    }

    // if (stateData && stateData.updateCred) {
    //   setIsStepperStart(true);
    //   setActiveStep(multiStapperSteps.indexOf('urjanetStep'));
    // }
  }, []);

  const handleLogout = () => {
    auth.signOut();
  };
  return (
    <>
      <Toaster toastOptions={{ duration: 4000 }} />

      <div className="relative">
        {isLoading && (
          <div className="absolute top-0 left-0 right-0 z-20 bottom-0 bg-white h-screen">
            <Loader showLoaderOnly={true} />
          </div>
        )}
        {/* <Popover /> */}
        <div className="flex item-center justify-center h-screen bg-neutral ">
          <div className="m-auto bg-white w-full h-screen items-center">
            <div className="h-screen">
              <div className={'h-[100px]'}>
                <Header />
              </div>

              <div className={'h-[calc(100vh-290px)] mx-5'}>
                {/* <div> */}
                {/* <Router> */}
                <Routes>
                  <Route path="/:state?" element={<HomeV2 />} />
                  <Route path="*" element={<PageNotFound />} />
                  <Route element={<PrivateRoutes handleLogout={handleLogout} />}>
                    <Route path="utility">
                      <Route index element={<UtilityConnect />} />
                      <Route path="connect" element={<UtilityConnect mode="create" />} />
                      <Route path="status" element={<UtilityStatus />} />
                      <Route path="update" element={<UtilityConnect mode="update" />} />
                    </Route>
                  </Route>
                </Routes>
                {/* </Router> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
