import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { URJANET_UTILITY_LIST } from '../../GraphQL/Queries';
import { SlArrowUp, SlArrowDown } from 'react-icons/sl';
import thunderIcon from '../../assets/icons/thunder-icon.png';
import { CgSpinner } from 'react-icons/cg';
import { debounce } from 'lodash';
import InputField from '../InputFields/InputField';

const AutocompleteInput = ({
  selectedOption,
  setSelectedOption,
  isLeftIcon = true,
  icon = thunderIcon,
  placeholder = ''
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  // const [selectedOption, setSelectedOption] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  // const [visibleItems, setVisibleItems] = useState([]);
  const autoCompleteRef = useRef(null);

  const handleOptionClick = (selectedOption) => {
    setSelectedOption(selectedOption);
    setSearchTerm(selectedOption.providerName);
    setIsOpen(false);
  };

  const [fetchData, { loading, data }] = useLazyQuery(URJANET_UTILITY_LIST);

  const debouncedSearch = useCallback(
    debounce((term) => {
      fetchData({ variables: { providerNameContains: term, offset: 0, first: 100 } });
    }, 500),
    []
  );

  const handleInputChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    if (term.trim() === '' && term.length === 0) {
      setSearchResults([]); // Clear search results if the search term is empty
      setSearchTerm();
      setIsOpen(false);
    } else {
      debouncedSearch(term);
    }
  };

  const handleClickOutside = (event) => {
    if (autoCompleteRef.current && !autoCompleteRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  // Handle data changes when the query response is received
  useEffect(() => {
    if (searchTerm && data && data.urjanetUtilityProvider) {
      data.urjanetUtilityProvider.edges ? setIsOpen(true) : setIsOpen(false);
      setSearchResults(data.urjanetUtilityProvider.edges);
    }
  }, [data]);

  useEffect(() => {
    if (searchTerm !== selectedOption.providerName) {
      setSelectedOption('');
    }
  }, [searchTerm]);

  return (
    <div
      className="relative flex flex-col items-center w-full rounded-lg z-[1]"
      ref={autoCompleteRef}>
      <div className="flex w-full">
        <input
          type="text"
          value={searchTerm}
          onInput={handleInputChange}
          placeholder={placeholder}
          className="peer h-full w-full border-b-2 border-primary bg-transparent pt-[16px] pb-[12px] text-[22px] leading-[28px] font-normal text-primary outline outline-0 transition-all placeholder-shown:border-quaternary placeholder:text-quaternary focus:border-primary focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
          // className="pr-2 bg-quaternary w-full text-lg lg:text-xl h-[50px] !text-primary focus:!ring-0  !outline-none placeholder:text-primary placeholder:text-lg lg:placeholder:text-xl  [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
        />

        {loading ? (
          <CgSpinner
            size={20}
            className="animate-spin flex items-center text-primary justify-between h-[50px]  absolute right-[20px]"
          />
        ) : (
          <button
            onClick={() => setIsOpen((prev) => !prev)}
            className="flex items-center text-primary justify-between cursor-pointer text-lg lg:text-xl h-[50px] absolute right-[20px] ">
            {!isOpen ? <SlArrowDown className="h-3" /> : <SlArrowUp className="h-3" />}
          </button>
        )}

        {/* {loading && (
          <CgSpinner
            size={20}
            className="animate-spin flex items-center text-primary justify-between h-[50px] "
          />
        )} */}
      </div>

      {!loading && isOpen && (
        <ul className="bg-quaternary absolute top-[50px] flex flex-col items-start w-full overflow-x-auto max-h-[300px] overflow-y-auto border-b-1 border-t-1 border-1 border-solid border-primary  [&>*:last-child]:border-b-1 [&>*:last-child]:border-primary [&>*:last-child]:border-solid [&>*:last-child]:border-1  [&>*:last-child]:border">
          {searchResults.map((result) => (
            <li
              key={result.node.id}
              className="flex py-4 pr-2 cursor-pointer hover:bg-gray-100 w-full border border-1 border-solid border-primary border-b-0 text-primary text-md lg:text-lg min-h-[50px] items-center overflow-x-scroll overflow-y-hidden "
              onClick={() => handleOptionClick(result.node)}>
              {isLeftIcon && <img className="mx-4 w-[12px] h-[18px]" src={icon} />}
              <h3 className="justify-left whitespace-nowrap">{result.node.providerName}</h3>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AutocompleteInput;
