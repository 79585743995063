import React, { useContext, useEffect, useState } from 'react';
import AutocompleteInput from '../../../components/AutocompleteInput/AutocompleteInput';
import { UserContext } from '../../../providers/user.provider';
import { useMutation } from '@apollo/client';
import { ENROLL_ACCOUNT, UPDATE_CREDENTIAL } from '../../../GraphQL/Mutations';
import { toast } from 'react-hot-toast';
import InputField from '../../../components/InputFields/InputField';
import { useNavigate } from 'react-router-dom';
import Common from '../Components/Common/Common';
import { UtilityStatusType } from '../../../Types';

const defaultSteps = (mode) =>
  mode === 'update'
    ? ['searchUtilityScreen']
    : [
        'searchUtilityScreen'
        // 'credentialsScreen'
      ];

const UtilityConnect = ({ mode }) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [multiStapperSteps, setMultiStapperSteps] = useState(defaultSteps(mode));
  const [activeStep, setActiveStep] = useState(
    mode === 'update'
      ? multiStapperSteps.indexOf('searchUtilityScreen')
      : multiStapperSteps.indexOf('searchUtilityScreen')
  );

  // contexts
  const {
    isLoading,
    setIsLoading,
    setUserProfile,
    ownerId,
    urjanetIntegrationId,
    firebaseLogout,
    urjanet,
    requestTokenResponse,
    setUrjanet
  } = useContext(UserContext);

  //   graphql mutations
  const [urjanetEnrollAccount] = useMutation(ENROLL_ACCOUNT);
  const [urjanetUpdateCredentials] = useMutation(UPDATE_CREDENTIAL);

  const signinToUtilityAccount = async () => {
    setIsLoading(true);

    try {
      const urjanetEnrollAccountResponse = await urjanetEnrollAccount({
        variables: {
          input: {
            credentials: { type: 'BASIC', clientID: selectedOption.providerId },
            type: 'UTILITY',
            ownerID: ownerId,
            integrationID: urjanetIntegrationId,
            name: 'Urjanet'
          }
        }
      });

      if (urjanetEnrollAccountResponse) {
        setIsLoading(false);
        // toast.success(`Successfully Connected`);
        console.log(urjanetEnrollAccountResponse);
        // fetchUserUtility();
        setUrjanet({
          id: urjanetEnrollAccountResponse.data.enrollAccount.id,
          status: urjanetEnrollAccountResponse.data.enrollAccount.credentialStatus
        });
        navigate('/utility/status', { replace: true });
        // firebaseLogout();
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleStepUp = () => {
    const prevStepIndex = activeStep - 1;
    if (prevStepIndex >= 0) {
      setActiveStep(prevStepIndex);
    }
  };

  const handleStepDown = () => {
    const nextStepIndex = activeStep + 1;
    if (nextStepIndex < multiStapperSteps.length) {
      setActiveStep(nextStepIndex);
    }
  };

  const handleUtilitySelectButton = () => {
    console.log(selectedOption);
    setActiveStep(multiStapperSteps.indexOf('searchUtilityScreen') + 1);
  };

  const updateUtilityAccount = async (username, password) => {
    if (urjanet.id) {
      setIsLoading(true);
      try {
        const urjanetUpdateCredentialsRes = await urjanetUpdateCredentials({
          context: {
            headers: {
              Authorization: `Bearer ${requestTokenResponse}`
            }
          },
          variables: {
            input: {
              credentialID: urjanet.id,
              username,
              password
            }
          }
        });

        if (urjanetUpdateCredentialsRes) {
          setIsLoading(false);
          // toast.success(`Thank you, we will update you soon on your email`);
          // fetchUserUtility();

          navigate('/utility/status', { replace: true });
          window.location.reload();
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
        // toast.error(error.message);
      }
    }
  };

  // Update default steps and active step when mode changes
  useEffect(() => {
    const newDefaultSteps = defaultSteps(mode);
    const newActiveStep =
      mode === 'update'
        ? newDefaultSteps.indexOf('credentialsScreen')
        : newDefaultSteps.indexOf('searchUtilityScreen');

    setMultiStapperSteps(newDefaultSteps);
    setActiveStep(newActiveStep);

    if (urjanet) {
      if (urjanet.status === UtilityStatusType.Pending) {
        navigate('/utility/status', { replace: true });
      } else if (urjanet.status === UtilityStatusType.Failed) {
        navigate('/utility/update', { replace: true });
      } else if (urjanet.status === UtilityStatusType.Success) {
        navigate('/utility/status', { replace: true });
      } else {
        navigate('/utility/connect', { replace: true });
      }
    } else {
      navigate('/utility/connect', { replace: true });
    }
  }, [mode, urjanet]);

  return (
    <>
      {activeStep === multiStapperSteps.indexOf('searchUtilityScreen') && (
        <Common
          heading={
            <p className="text-primary text-[18px] md:text-[24px] lg:text-[32px] leading-1 lg:leading-[40px] py-2 mb-[14px]">
              <span className="mr-[24px]">1.</span>Please select your electric utility provider.
            </p>
          }
          activeStep={activeStep}
          multiStapperSteps={multiStapperSteps}
          handleStepUp={handleStepUp}
          handleStepDown={handleStepDown}>
          <div className="md:pl-[38px] pl-0">
            <p className="text-[14px] md:text-[18px] lg:text-[24px] lg:leading-[32px] leading-1 text-primary">
              With your energy consumption history, we can create a completely personalized energy
              upgrade and savings plan built for your home & habits.
            </p>
            <div className="p-1 w-full items-center justify-center text-center">
              <AutocompleteInput
                selectedOption={selectedOption}
                setSelectedOption={(e) => setSelectedOption(e)}
                placeholder="Type electric utility name"
              />
            </div>

            <div className="text-left mt-8">
              <button
                className="bg-[#F1D972] font-aeonik-bold py-3 px-14 text-primary rounded-md text-lg border-[#F1D972] cursor-pointer disabled:cursor-not-allowed disabled:bg-neutral disabled:text-gray-500 hover:opacity-80"
                disabled={!selectedOption}
                onClick={() => {
                  selectedOption && signinToUtilityAccount();
                }}>
                Next
              </button>
            </div>
          </div>
        </Common>
      )}

      {activeStep === multiStapperSteps.indexOf('credentialsScreen') && (
        <Common
          heading={
            <p className="text-primary text-[18px] md:text-[24px] lg:text-[32px] leading-1 lg:leading-[40px] py-2 mb-[14px]">
              <span className="mr-[24px]">{mode === 'update' ? '1.' : '2.'}</span>Sign into your
              electric utility provider account.
            </p>
          }
          activeStep={activeStep}
          multiStapperSteps={multiStapperSteps}
          handleStepUp={handleStepUp}
          handleStepDown={handleStepDown}>
          <div className="md:pl-[38px] pl-0">
            <div className=" py-4 p-1 w-full items-center justify-center text-center">
              <InputField
                label={'Username'}
                placeholder={'Jenna88'}
                className={{ inputClasses: 'text-lg', labelClasses: 'font-aeonik-bold' }}
                name={'username'}
                variants={'static'}
                value={username}
                onChange={(e) => setUsername(e.currentTarget.value)}
              />
            </div>
            <div className="py-4 p-1 w-full items-center justify-center text-center">
              <InputField
                type={'password'}
                label={'Password'}
                placeholder={'*******'}
                className={{ inputClasses: 'text-lg', labelClasses: 'font-aeonik-bold' }}
                name={'password'}
                variants={'static'}
                value={password}
                onChange={(e) => setPassword(e.currentTarget.value)}
              />
            </div>

            <div className="text-left mt-8">
              {mode === 'create' && (
                <button
                  className="bg-[#F1D972] font-aeonik-bold py-3 px-14 text-primary rounded-md text-lg border-[#F1D972] cursor-pointer disabled:cursor-not-allowed disabled:bg-neutral disabled:text-gray-500 hover:opacity-80"
                  disabled={!(selectedOption && username && password)}
                  onClick={() => {
                    selectedOption &&
                      username &&
                      password &&
                      signinToUtilityAccount(username, password);
                  }}>
                  Next
                </button>
              )}
              {mode === 'update' && (
                <button
                  className="bg-[#F1D972] font-aeonik-bold py-3 px-14 text-primary rounded-md text-lg border-[#F1D972] cursor-pointer disabled:cursor-not-allowed disabled:bg-neutral disabled:text-gray-500 hover:opacity-80"
                  disabled={
                    !(requestTokenResponse && urjanet && urjanet.id && username && password)
                  }
                  onClick={() => {
                    username && password && updateUtilityAccount(username, password);
                  }}>
                  Update
                </button>
              )}
            </div>
          </div>
        </Common>
      )}
    </>
  );
};

export default UtilityConnect;
