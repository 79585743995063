import React from 'react';
import StickyButton from '../../../../components/StickyButton/StickyButton';
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';

const Common = ({
  children,
  heading,
  activeStep,
  multiStapperSteps,
  handleStepUp,
  handleStepDown
}) => {
  return (
    <>
      <div className={`xl:mx-[211px] mx-[10px] mb-6 lg:pt-[150px] pt-0`}>
        <div className="text-left">
          <p className="text-primary text-sm lg:text-2xl leading-1 lg:leading-[28px] py-2">
            {/* <span className="mr-[24px]">1.</span>Please select your electric utility provider. */}
            {heading}
          </p>

          {children}
          {/* <div className="md:pl-[38px] pl-0">
            <p className="text-sm text-primary">
              With your energy consumption history, we can create a completely personalized energy
              upgrade and savings plan built for your home & habits.
            </p>
            <div className="p-1 w-full items-center justify-center text-center">
              <AutocompleteInput
                selectedOption={selectedOption}
                setSelectedOption={(e) => setSelectedOption(e)}
                placeholder="Type electric utility name"
              />
            </div>

            <div className=" py-4 p-1 w-full items-center justify-center text-center">
              <InputField
                label={'Username'}
                placeholder={'Jenna88'}
                className={{ inputClasses: 'text-lg', labelClasses: 'font-aeonik-bold' }}
                name={'username'}
                variants={'static'}
                value={username}
                onChange={(e) => setUsername(e.currentTarget.value)}
              />
            </div>
            <div className="py-4 p-1 w-full items-center justify-center text-center">
              <InputField
                type={'password'}
                label={'Password'}
                placeholder={'*******'}
                className={{ inputClasses: 'text-lg', labelClasses: 'font-aeonik-bold' }}
                name={'password'}
                variants={'static'}
                value={password}
                onChange={(e) => setPassword(e.currentTarget.value)}
              />
            </div>

            <div className="text-left mt-8">
              <button
                className="bg-[#F1D972] font-aeonik-bold py-3 px-14 text-primary rounded-md text-lg border-[#F1D972] cursor-pointer disabled:cursor-not-allowed disabled:bg-neutral disabled:text-gray-500 hover:opacity-80"
                disabled={!(selectedOption && username && password)}
                onClick={() => {
                  selectedOption &&
                    username &&
                    password &&
                    signinToUtilityAccount(username, password);
                }}>
                Next
              </button>
            </div>
          </div> */}
        </div>
      </div>
      <div>
        <StickyButton className={'text-right justify-end'}>
          <div className="flex gap-2">
            <button
              className="bg-[#F1D972] font-aeonik-bold py-3 px-3 text-primary rounded-[2px] text-lg border-[#F1D972] cursor-pointer disabled:cursor-not-allowed disabled:bg-neutral disabled:text-gray-500 hover:opacity-80"
              onClick={handleStepUp} // Handle step up
              disabled={activeStep === 0}>
              <BsArrowUp />
            </button>
            <button
              className="bg-[#F1D972] font-aeonik-bold py-3 px-3 text-primary rounded-[2px] text-lg border-[#F1D972] cursor-pointer disabled:cursor-not-allowed disabled:bg-neutral disabled:text-gray-500 hover:opacity-80"
              onClick={handleStepDown} // Handle step up
              disabled={activeStep === multiStapperSteps.length - 1}>
              <BsArrowDown />
            </button>
          </div>
        </StickyButton>
      </div>
    </>
  );
};

export default Common;
