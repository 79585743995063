import React, { useRef, useEffect } from 'react';
import './AtomicShapesAnimation.scss';

const AtomicShapesAnimation = ({ children }) => {
  const shapeContainerRef = useRef(null);

  useEffect(() => {
    const shapeContainer = shapeContainerRef.current;

    const shapeContainers = [];

    for (let i = 1; i <= 50; i++) {
      const shape = document.createElement('div');
      shape.className = `shape-container--${i} shape-animation`;
      shape.innerHTML = '<div class="random-shape"></div>';
      shapeContainers.push(shape);
    }

    shapeContainers.forEach((shape) => {
      shapeContainer.appendChild(shape);
      //   animateShape(shape);
    });

    return () => {
      shapeContainers.forEach((shape) => {
        shapeContainer.removeChild(shape);
      });
    };
  }, []);

  return (
    <>
      <div className="shape" ref={shapeContainerRef}></div>
      <div className="atomic-animation-container">
        <div className="w-full xl:top-[14%] top-[20%]">{children}</div>
      </div>
    </>
  );
};

export default AtomicShapesAnimation;
