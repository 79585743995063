import React, { useContext } from 'react';
import { auth } from '../../firebase.config';
import dayLightWhiteLogo from '../../assets/images/daylight-white-logo.png';
import { UserContext } from '../../providers/user.provider';
import Cookies from 'js-cookie';

const Header = () => {
  const { currentUser, firebaseLogout, setIsManualSignUp, authToken } = useContext(UserContext);

  return (
    <div className="bg-primary text-white p-6 text-4xl font-light h-[100px] flex justify-center items-center font-aeonik-regular">
      <img className="w-[181px]" src={dayLightWhiteLogo} alt="" />
      {authToken && (
        <div
          className="absolute right-5 text-lg cursor-pointer"
          onClick={() => {
            auth.signOut();
            // localStorage.clear();
            Cookies.remove('daylight_access_token');
            window.location.reload();
            setIsManualSignUp(false);
          }}>
          Sign out
        </div>
      )}
    </div>
  );
};

export default Header;
