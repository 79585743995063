import CryptoJS from 'crypto-js';
import { encryptionSecretKey } from '../Constants';

export const validateEmail = (email) => {
  // Regular expression to check email format
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

// export function redirectToExternalUrl(url, authToken, paramName = 'urlFrom') {
//   const redirectUrl = `${url}?${paramName}=${authToken}`;

//   window.location.href = redirectUrl;
// }

export const encryptionAES = (inputValue, secretKey = encryptionSecretKey.secretKey) => {
  const encrypted = CryptoJS.AES.encrypt(inputValue, secretKey);
  return encrypted.toString();
};

export const decryptionAES = (encryptedValue, secretKey = encryptionSecretKey.secretKey) => {
  if (encryptedValue) {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedValue, secretKey);
      const decrypted = bytes.toString(CryptoJS.enc.Utf8);
      return { success: true, data: decrypted };
    } catch (error) {
      return { success: false, error };
    }
  }
};

export const handleDecrypt = (encryptedValue) => {
  if (encryptedValue) {
    const result = decryptionAES(encryptedValue);

    if (result.success) {
      return result;
    } else {
      return (window.location.href = document.referrer);
    }
  }
};

export function redirectToExternalUrl(url, authToken, encryption = true, paramName = 'urlFrom') {
  let redirectUrl;
  if (encryption) {
    redirectUrl = `${url}?${paramName}=${encodeURIComponent(encryptionAES(authToken))}`;
  } else {
    redirectUrl = `${url}?${paramName}=${authToken}`;
  }

  return (window.location.href = redirectUrl);
}
